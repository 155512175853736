import React from 'react';
import {navigate} from 'gatsby';
import Layout from '../components/layout/layout';
import styles from './home.module.scss';
import logoSrc from '../images/logo.svg';

const Home = ({location}) => {
  return (
    <Layout location={location}>
      <div className={styles.home}>
        <img src={logoSrc} alt="logo" />
        <p>
          Welcome to CELLR! The one stop for you to curate every part of your
          wine life, connect with vineyards, insight on specials, and locate
          hard to source wines!
        </p>
        <div className={styles.home__buttons}>
          <button
            onClick={() => {
              navigate('/beta/');
            }}
          >
            Become beta user
          </button>
          <button
            onClick={() => {
              navigate('/about/');
            }}
          >
            Learn more
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
